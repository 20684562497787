<script lang="ts">
	import mapMd from "../assets/images/map.png?webp&h=400&imagetools";
	import map from "../assets/images/map.png?webp&h=1080&imagetools";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import Icon from "./Icon.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./Partners.translations.json";
	import { partnersIconMap } from "../assets/partners/partnersIconMap.js";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";

	const partners = [
		"volkswagen",
		"skoda",
		"audi",
		"seat",
		"bentley",
		"lamborghini",
		"lear",
		"scania",
		"stellantis",
		"man",
		"bwp",
		"magna",
		"benz",
		"daimler",
		"volvo",
		"kia",
		"daf",
		"alunited",
		"tesla",
		"bmw",
		"porsche",
		"forvia",
		"gm",
		"ineos",
	];

	const translate = getTranslate(translations);
</script>

<div class="relative w-full pb-[11.563rem] pt-[4.375rem]">
	<StaticPicture
		image={map}
		width={1920}
		height={1080}
		alt={translate("imageAlt")}
		imgClass="h-full object-contain mx-auto"
		class="absolute left-0 right-0 top-0"
		sourcesets={{
			"<md": mapMd,
		}}
	/>
	<div class="container">
		<p use:flyInOnScroll class="mb-[3.125rem] text-xl font-semibold leading-[1.13] md:text-2xl lg:w-1/2 xl:text-4xl">
			{translate("title")}
		</p>
		<div class="px-2 md:mt-32 lg:px-8 xl:px-28">
			<div
				use:flyInOnScroll
				class="flex flex-wrap items-center justify-center gap-5 sm:gap-x-10 md:gap-y-[4.5rem] lg:gap-x-16 xl:gap-x-[5.25rem]"
			>
				{#each partners as partner}
					<Icon
						icon={partnersIconMap[partner]?.default ?? ""}
						class="lg:scale-80 !aspect-auto max-w-44 scale-[65%] md:scale-75 xl:scale-100"
					/>
				{/each}
			</div>
		</div>
	</div>
</div>
