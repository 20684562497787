import type { ImportGlobFunction } from "vite";
import type { RegExpWithGroups } from "../../../../core/utils/RegExpWithGroups.js";

const icons = (
	import.meta as unknown as {
		glob: ImportGlobFunction;
	}
).glob("./*.svg", { eager: true, query: "raw" });

const regex = /(?<name>[^/]+)\.svg$/u as RegExpWithGroups<{ name: string }>;

export const partnersIconMap = Object.fromEntries(
	Object.entries(icons).map(([path, content]) => {
		const match = regex.exec(path);
		if (!match) {
			throw new Error(`Invalid icon path: ${path}`);
		}
		return [match.groups.name, content as { default: string }];
	}),
);
